import * as React from "react"
import { Zoom } from "react-awesome-reveal";

const Pricing = () => (
  <section id="pricing">
    <Zoom triggerOnce fraction={0.3}>

      <div className="container py-5">
        <h2 className="fw-bold text-center">Áraim</h2>
        <p className="text-center">Érvényes: 2021.05.23.-tól</p>
        <table class="table table-striped table-hover mt-2">
          <caption>A fenti árak tájékoztató jellegűek. A munkadíj minden esetben tartalmazza a kiszállási költséget, de nem tartalmazza a javítás során felmerülő anyagszükségletet.</caption>
          <thead>
            <tr>
              <th scope="col">Szolgáltatás</th>
              <th scope="col">Ár</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Első megkezdett óra</th>
              <td>8000 Ft</td>
            </tr>
            <tr>
              <th scope="row">Minden további megkezdett fél óra</th>
              <td>3000 Ft</td>
            </tr>
            <tr>
              <th scope="row">Nagyobb munkák</th>
              <td colspan="2">Egyedi árajánlat</td>
            </tr>
            <tr>
              <th scope="row">Árajánlatkérés</th>
              <td colspan="2">Ingyenes</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Zoom>
  </section>
);

export default Pricing;