import * as React from "react"
import Header from "../components/header"
import About from "../components/sections/About"
import Faq from "../components/sections/Faq"
import Pricing from "../components/sections/Pricing"
// import References from "../components/sections/References"
import Services from "../components/sections/Services"
// import Chatbot from "../components/sections/Chatbot"
import Contact from "../components/sections/Contact"
import Layout from "../components/common/Layout/Layout"

const IndexPage = () => {
  return (
    <Layout>
      {/* <Chatbot></Chatbot> */}
      <Header></Header>
      <About></About>
      <Services></Services>
      <Pricing></Pricing>
      <Faq></Faq>
      {/* <References></References> */}
      <Contact></Contact>
    </Layout>
  )
}

export default IndexPage
