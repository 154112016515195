import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticQuery } from "gatsby";
import * as React from "react"
import Door from '@images/icons/door.svg';
import Socket from '@images/icons/socket.svg';
import Lamp from '@images/icons/lamp.svg';
import Toilet from '@images/icons/toilet.svg';
import WashingMachine from '@images/icons/washing-machine.svg';
import Basin from '@images/icons/basin-2.svg';
import PaintBrush from '@images/icons/paint-brush.svg';
import Drill from '@images/icons/drill-1.svg';
import Tap from '@images/icons/tap-2.svg';
import Shower from '@images/icons/shower.svg';
import Screwdriver from '@images/icons/screwdriver-1.svg';
import Parquet from '@images/icons/parquet.svg';
import Tiles from '@images/icons/tiles.svg';
import BrickWall from '@images/icons/brick-wall-1.svg';
import { Fade } from "react-awesome-reveal";

const Services = () => {
    return (
        <section className="section-services" id="services">
            <div class="container">
                <h2 className="mb-5 text-center fw-bold">
                    Szolgáltatások
                </h2>
                <div className="row">
                    <Fade className="col-md-3 g-5 col-6" triggerOnce cascade damping={0.05} duration={600}>
                        <div class="service-item">
                            <Door className="service-item__icon" />
                            <span className="service-item__title fs-6">zár, kilincs csere</span>
                        </div>
                        <div class="service-item">
                            <Socket className="service-item__icon" />
                            <span className="service-item__title fs-6">konnektor, villanykapcsoló csere</span>
                        </div>
                        <div class="service-item">
                            <Lamp className="service-item__icon" />
                            <span className="service-item__title fs-6">falikar, mennyezeti lámpa csere</span>
                        </div>
                        <div class="service-item">
                            <Drill className="service-item__icon" />
                            <span className="service-item__title fs-6">kép, karnis, polc felrakása</span>
                        </div>
                        <div class="service-item">
                            <WashingMachine className="service-item__icon" />
                            <span className="service-item__title fs-6">mosógép, mosogatógép bekötése</span>
                        </div>
                        <div class="service-item">
                            <Toilet className="service-item__icon" />
                            <span className="service-item__title fs-6">WC tartály, ülőke csere</span>
                        </div>
                        <div class="service-item">
                            <Basin className="service-item__icon" />
                            <span className="service-item__title fs-6">mosogató, mosdókagyló, WC kagylócsere</span>
                        </div>
                        <div class="service-item">
                            <Tap className="service-item__icon" />
                            <span className="service-item__title fs-6">csapok cseréje</span>
                        </div>
                        <div class="service-item">
                            <Shower className="service-item__icon" />
                            <span className="service-item__title fs-6">zuhanykabin cseréje</span>
                        </div>
                        <div class="service-item">
                            <Screwdriver className="service-item__icon" />
                            <span className="service-item__title fs-6">új bútor összeszerelés</span>
                        </div>
                        <div class="service-item">
                            <Parquet className="service-item__icon" />
                            <span className="service-item__title fs-6">laminált padló lerakása</span>
                        </div>
                        <div class="service-item">
                            <BrickWall className="service-item__icon" />
                            <span className="service-item__title fs-6">kisebb faljavítás</span>
                        </div>
                        <div class="service-item">
                            <PaintBrush className="service-item__icon" />
                            <span className="service-item__title fs-6">falfestés</span>
                        </div>
                        <div class="service-item">
                            <Tiles className="service-item__icon" />
                            <span className="service-item__title fs-6">összetört, sérült csempék, burkolatok javítása</span>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}


export default Services;