import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react"
import Flip from 'react-reveal/Flip';
import { AttentionSeeker, Fade, Pulse } from "react-awesome-reveal";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const query = graphql`
    {
        file(relativePath: { eq: "header-min.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
    }
`
const Header = () => {
    // const data = useStaticQuery(
    //     graphql`
    //       query {
    //         desktop: file(relativePath: { eq: "header-min.jpg" }) {
    //           childImageSharp {
    //             gatsbyImageData(
    //                 layout: FIXED
    //                 placeholder: blurred
    //                 formats: [AUTO, WEBP]
    //             )
    //           }
    //         }
    //       }
    //     `
    // )

    // const imageData = [
    //     data.desktop.data.file.childImageSharp.gatsbyImageData,
    //     `linear-gradient(to right bottom, rgba(126,213,111,.8),rgba(40,180,133,.8))`
    // ].reverse();

    return (
        // <BackgroundImage
        //     Tag="section"
        //     fluid={imageData}
        //     className="header"
        //     backgroundColor={`#040e18`}
        //     loading="eager"
        // >
            <div className="header">
            <div class="container" id="home">
                <div className="header__text-box py-5">
                    <h1 className="heading-primary me-3 text-center text-md-start">
                        <span className="h1 heading-primary--main">
                            <Flip bottom triggerOnce>
                                Szüksége lenne egy ezermesterre?
                            </Flip>
                        </span>
                    </h1>
                    <p className="lead header__text header__text--sub col-md-8 text-center text-md-start ">
                        Elromlott? Eltört? Leszakadt? Cserére szorul, vagy csak újra vágyik? <br></br> A Férfikölcsönbe (majdnem!) mindenre talál megoldást! Ha valahol festeni, fúrni, cserélni, javítani vagy szerelni kell, ott gyors, minőségi és garanciális munkavégzést biztosítok annak érdekében, hogy az Ön élete minél előbb visszatérhessen a normális kerékvágásba!
                    </p>
                    <div className="header__call-to-action  text-center text-md-start">
                        <a href="#contact" className="btn btn--white">Kapcsolatfelvétel</a>
                    </div>
                </div>
            </div>
            </div>

        // </BackgroundImage>
    )
};


export default Header;

