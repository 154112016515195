import { StaticImage } from "gatsby-plugin-image";
import React from "react"
import { Zoom } from "react-awesome-reveal";

const About = () => {
    return (
        <section className="section-about" id="about">
            <Zoom triggerOnce fraction={0.3}>

                <div className="container py-5">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-4 d-flex justify-content-center">
                            <StaticImage src="../../images/p-min.png" width={300} alt="Profile photo" placeholder="blurred" />

                        </div>
                        <div className="col-md-8 gy-5">
                            <h2 className="h2 fw-bold mb-3">
                                Rólam
                        </h2>
                            <p className="paragraph">
                                A Férfikölcsönbe azért jött létre, hogy a háztartásokban előforduló kisebb javításokhoz és problémákhoz, részleges vagy teljes felújítási munkákhoz segítő kezet nyújtson. Célom minőségi megoldásokkal és gyors munkavégzéssel megkönnyíteni ügyfeleim mindennapjait. Hiszen a legtöbben tapasztalhatták már, hogy ma már nehéz egy néhány órás munkára rövid időn belül szakembert találni. Arról nem is beszélve, hogy az árak az egekbe szöktek és sokszor már az állapotfelmérés után is fizetnünk kell, holott a munkálatok még el sem kezdődtek. Spóroljon magának időt és pénzt a kisebb hétköznapi munkálatokon, válassza a több évnyi tapasztalattal rendelkező mesterembert, válassza a Férfikölcsönbe szolgáltatásait! Minden általam elvégzett munkára garanciát vállalok.
                        </p>
                        </div>
                    </div>
                </div>
            </Zoom>

        </section>
    )
};

export default About;