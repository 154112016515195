import React from 'react';
import Collapsible from 'react-collapsible';

const FaqItem = ({ title, children }) => (
    <Collapsible
        className="faq w-100"
        openedClassName="faq active w-100"
        triggerClassName="faq__title w-100"
        triggerOpenedClassName="faq__title active"
        trigger={title}
        easing="ease-out"
        contentInnerClassName="faq__content w-100"
        transitionTime={300}
        triggerTagName="button"
    >
        <p>
            {children}
        </p>
    </Collapsible>
);

export default FaqItem;
