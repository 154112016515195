import * as React from "react"
import FaqItem from "../common/FaqItem";
import { Zoom } from "react-awesome-reveal";

const FAQs = [
    {
        title: 'Mi a munkavégzés menete?',
        content: () => (
            <>
                Telefonon vagy emailen kapcsolatfelvétel. A megkeresést követően alaposan megbeszéljük az
                elvégezendő munka részleteit. Egy vagy több napos nagyobb munka esetén árajánlat készítés.
                Minden elvégzett munkára garanciát vállalok.
            </>
        )
    },
    {
        title: 'Milyen időintervallumban dolgozom?',
        content: () => (
            <>
                <ul>
                    <li>Hétköznap 8:00 és 18:00 óra között</li>
                    <li>Hétvége/Ünnepnap: külön megállapodás esetén</li>
                </ul>
            </>
        )
    },
    {
        title: 'Hogyan történik az anyagbeszerzés?',
        content: () => (
            <>
                Előzetes tájékoztatás alapján próbálok felkészülni a javítás során felmerülő
                anyagszükségletekre. Sok esetben, viszont csak a munkavégzés során megkezdését követően
                derülnek ki dolgok. Ebben az esetben az anyagbeszerzésre fordított idő munkaidőnek számít.
                Természetesen, ha előzetesen felmérés készült, úgy a fent említettre ez nem vonatkozik.
            </>
        )
    },
    {
        title: 'Hol vállalok munkát?',
        content: () => (
            <>
                Budapest / Pest megye (Vecsés, Üllő, Monor és ezen települések vonzáskörzete)
            </>
        )
    }
]

const Faq = () => (
    <section className="section-faq" id="faq">
        <Zoom cascade triggerOnce duration={500}>
            <div className="container d-flex flex-column justify-content-center align-items-center">
                <h2 className="mb-5 text-center fw-bold heading-secondary">
                    Gyakran ismételt kérdések
            </h2>
                <Zoom cascade className="w-100" triggerOnce damping={0.1}>

                    {FAQs.map(({ title, content }) => (
                        <FaqItem title={title} key={title}>
                            {content()}
                        </FaqItem>
                    ))}
                </Zoom>
            </div>
        </Zoom>
    </section>
)

export default Faq;