import React, { useState } from "react"
import axios from "axios";
import { Link } from "gatsby"
import CallToAction from "./CallToAction"
import { Zoom } from "react-awesome-reveal";

const Contact = () => {
    const [formState, setFormState] = useState({
        nev: null,
        email: null,
        uzenet: null,
        telefonszam: null,
        accepted: false
    });
    const [serverState, setServerState] = useState({
        submitting: false,
        submitted: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            setServerState({ submitted: true })
            form.reset();
        }
    };
    const handleOnSubmit = e => {
        e.preventDefault();
        const form = e.target;
        setServerState({ submitting: true });
        axios({
            method: "post",
            url: "https://getform.io/f/2fbe3f36-9c5a-4d1f-a003-54202b694d7c",
            data: new FormData(form)
        })
            .then(r => {
                handleServerResponse(true, "Köszönjük megkeresésést!", form);
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form);
            });
    };

    const handleFormChange = e => {
        console.log(e.target);
        setFormState({
            ...formState,
            [e.target.id]: e.target.value,
        })
        console.log(formState);
    }
    const handleAcceptChange = e => {
        setFormState({
            ...formState,
            [e.target.id]: e.target.checked,
        })
    }
    return (
        <>
            <CallToAction></CallToAction>
            <section className="section-contact position-relative d-flex justify-content-center">

                <div class="or-divider position-absolute d-flex align-items-center">
                    VAGY
            </div>
                <div className="container">
                    <Zoom triggerOnce duration={500} fraction={0.3}>

                        <h2 className="mb-4 text-center fw-bold mt-5">
                            Kérjen árajánlatot!
                        </h2>
                        {serverState.submitted &&
                            <div class="alert alert-success" role="alert">
                                Köszönjük a megkeresését!
                            </div>
                        }
                        {serverState.submitting &&
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Töltés...</span>
                                </div>
                            </div>
                        }
                        {!serverState.submitted && !serverState.submitting &&
                            <div class="form-wrapper">
                                <form onSubmit={handleOnSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label for="lastName" className="form-label">
                                                    Név
                                    <input type="text" className="form-control" id="nev" name="Név" value={formState.id} onChange={handleFormChange} />
                                                </label>
                                            </div>
                                            <div className="row">
                                                <label for="lastName" className="form-label">
                                                    E-mail cím
                                    <input type="text" className="form-control" id="email" name="E-mail cím" value={formState.id} onChange={handleFormChange} />
                                                </label>
                                            </div>
                                            <div className="row">
                                                <label for="lastName" className="form-label">
                                                    Telefonszám
                                    <input type="text" className="form-control" id="telefonszam" name="Telefonszám" value={formState.id} onChange={handleFormChange} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label for="lastName" className="form-label">
                                                    Üzenet
                                        <textarea type="textarea" rows="7" className="form-control" id="uzenet" name="Üzenet" value={formState.id} onChange={handleFormChange} ></textarea>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-check mt-1">
                                                <input class="form-check-input" type="checkbox" onChange={handleAcceptChange} name="Elfogadta az adatvédelmit?" id="accepted" />
                                                <label class="form-check-label" for="accepted">
                                                    Elfogadom az adatkezelési nyilatkozatot.
                                        </label>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-end">
                                            <button type="submit" class="btn btn--white mt-1" disabled={!formState.accepted}>Küldés</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </Zoom>

                </div>

            </section>
        </>
    )
};

export default Contact;