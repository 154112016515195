import React from 'react';
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Zoom } from "react-awesome-reveal";

const CallToAction = () => {

    return (
        <section className="call-to-action" id="contact">
            <div className="call-to-action__container container d-flex flex-column flex-md-row justify-content-around align-items-center     align-items-md-end" >
                <Zoom triggerOnce duration={500}>
                    <h3 className="h3 call-to-action__text"><FontAwesomeIcon icon={faPhone} className="pe-2" />Hívjon bizalommal!</h3>
                    <span className="h4 call-to-action__text">+36-30-492-6734</span>
                </Zoom>
            </div>

        </section>
    )
}

export default CallToAction;